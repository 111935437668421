body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #1a1d25;
}
a:link{ /*默认状态*/
  color: rgb(255, 255, 255);
  }
  a:visited{ /*浏览过的*/
  color:darkgrey;
  }
  a:hover{ /*悬浮状态*/
  color:yellow;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
